// HomePage.js
import React from "react";

const HomePage = () => {
  return (
    <div>
      <h1>Homepage</h1>
      <h2>Jayanti</h2>
    </div>
  );
};

export default HomePage;
